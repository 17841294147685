<template>
    <div>
        <div
            v-if="permissionsLoaded && hasAccess"
            id="app"
            class="container-fluid"
        >
            <div class="row flex-nowrap">
                <div class="col-auto px-0">
                    <AMMenu default-menu="null">
                        <template #section-one-heading="{collapsed}">
                            <AMMenuHeading
                                title="Behandle"
                                icon="repeat"
                                :collapsed="collapsed"
                            />
                        </template>

                        <template #section-one-items="{collapsed}">
                            <AMMenuItem
                                :to="{name: 'admin.processes.list'}"
                                :icon="['fas', 'stream']"
                                :collapsed="collapsed"
                                title="Processes"
                            />

                            <AMMenuItem
                                :to="{name: 'admin.jobs.list'}"
                                :icon="['fas', 'history']"
                                :collapsed="collapsed"
                                title="Jobs"
                            />

                            <AMMenuItem
                                :href="adminUrls.celeryFlower"
                                :icon="['fas', 'flower']"
                                :collapsed="collapsed"
                                target="_blank"
                                title="Celery Flower"
                            />
                        </template>
                    </AMMenu>
                </div>

                <div class="col px-0 d-flex flex-column">
                    <AMInAppAlerts />

                    <AMHeader :title="pageTitle" />

                    <RouterView
                        @toggle-mode="toggleMode($event)"
                    />

                    <div class="row footer m-0 mt-auto">
                        <div class="col-4">
                            <a
                                class="btn btn-link p-0"
                                href="https://ticket.imtins.com/status/endpoints"
                            >
                                Platform Status
                            </a>
                        </div>

                        <div class="col text-muted text-center">
                            <span class="copyright">&copy;{{ copyrightText }}</span>
                        </div>

                        <div class="col-4 text-right">
                            <button
                                ref="backToTop"
                                v-scroll-to="'#app'"
                                class="btn btn-link btn-back-to-top p-0"
                            >
                                <FontAwesomeIcon
                                    icon="caret-up"
                                />
                                Back to top
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Forbidden
            v-else-if="permissionsLoaded && !hasAccess"
        />
    </div>
</template>

<script>
    import AMHeader from '@imt/vue-admin-menus/src/components/AMHeader.vue';
    import AMInAppAlerts from '@imt/vue-admin-menus/src/components/AMInAppAlerts.vue';
    import AMMenu from '@imt/vue-admin-menus/src/components/AMMenu.vue';
    import AMMenuHeading from '@imt/vue-admin-menus/src/components/AMMenuHeading.vue';
    import AMMenuItem from '@imt/vue-admin-menus/src/components/AMMenuItem.vue';
    import dayjs from 'dayjs';
    import Vue from 'vue';
    import VueScrollTo from 'vue-scrollto';
    import {mapState} from 'vuex';

    import authMixin from './mixins/auth';


    Vue.use(VueScrollTo, {
        duration: 300,
        easing: 'ease',
        offset: 0,
        force: true,
        cancelable: true,
        onStart: false,
        onDone: false,
        onCancel: false,
        x: false,
        y: true
    });

    export default {
        name: 'App',
        components: {
            AMHeader,
            AMInAppAlerts,
            AMMenu,
            AMMenuHeading,
            AMMenuItem,
            'Forbidden': () => import('@imt/vue-kit-car/src/components/errors/Forbidden.vue'),
        },
        mixins: [
            authMixin,
        ],
        data() {
            return {
                adminUrls: {
                    celeryFlower: process.env.VUE_APP_ADMIN_URL_CELERY_FLOWER,
                },
            };
        },
        computed: {
            copyrightText: () => `Copyright ${dayjs().year()} IMT Insurance. All rights reserved.`,
            darkMode() {
                return this.mode === 'dark';
            },
            pageTitle() {
                return this.$route.meta.title || '';
            },
            ...mapState('toolbox', [
                'user',
                'permissions'
            ]),
        },
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/kit-car"
    @import "~@imt/vue-kit-car/src/sass/modules/multiselect"

    \:root
        --card-border-radius: 0.25rem
</style>
