import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate';
import {extend} from 'vee-validate';
import {
    required,
    max,
    regex
} from 'vee-validate/dist/rules';
import Vue from 'vue';

extend('max', max);
extend('regex', regex);
extend('required', {
    ...required,
    message: 'This field is required'
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
