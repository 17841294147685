import utils, {axiosWithAuth} from '@imt/vue-toolbox/src/utils';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const authedAxios = axiosWithAuth();

export const getters = {
    actions(state, storeGetters) {
        return state.availableTasks.map(action => {
            action.helpWording = storeGetters._objectifyHelpWording(storeGetters._parseHelpWording(action.description));
            action.description = storeGetters._parseDescription(action.description)[0];

            return action;
        });
    },
    _objectifyHelpWording: () => helpWording => {
        return mapValues(keyBy(helpWording, hw => {
            return hw.key;
        }), 'wording');
    },
    _parseDescription: () => description => {
        return description?.match(new RegExp(/^(([\w\W]+)\n\n)|[\w\W]+/, 'g'))?.map(m => m.trim()) || [''];
    },
    _parseHelpWording: () => description => {
        return description?.match(new RegExp(/(:\w+:\W+.+)\n/, 'g'))?.map(hw => {
            return {
                key: hw.split(': ')[0].substring(1),
                wording: hw.split(': ')[1].trim(),
            };
        }) || [];
    },
};

export const actions = {
    async fetchJobs(context, processId) {
        const response = await authedAxios.get(`/jobs/?filter[process_id]=${processId}`),
            jobs = utils.dataFormatter.deserialize(response.data);

        context.commit('SET_DATA', {field: 'jobs', data: jobs});

        return jobs;
    },
    async fetchVersion(context, id) {
        const response = await authedAxios.get(`/versions/${id}/?include=tasks,process`),
            version = utils.dataFormatter.deserialize(response.data);

        context.commit('SET_DATA', {field: 'version', data: version});

        return version;
    },
    async fetchActions(context) {
        const response = await authedAxios.get(`/actions/`) || {data: {}},
            availableActions = response.data.data || [];

        context.commit('SET_ACTIONS', availableActions);

        return availableActions;
    },
    async reorderTasks(context) {
        await context.state.version.tasks.forEach((task, index) => {
            context.dispatch('saveTask', {
                ...task,
                order: index,
            });
        });

        await context.state.deletedTasks.forEach(task => {
            context.dispatch('deleteTask', task.id);
        });

        context.commit('SET_DATA', {field: 'deletedTasks', data: []});
    },
    async saveTask(context, task) {
        if (!task.id) {
            task.type = 'Task';
            task.function = task.title;
            task.versionId = context.state.version.id;
        }

        const response = await authedAxios[task.id ? 'patch' : 'post'](`/tasks/${task.id ? task.id + '/' : ''}`,
            utils.dataFormatter.serialize({
                stuff: task,
            }),
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async deleteTask(context, taskId) {
        await authedAxios.delete(`/tasks/${taskId}/`);
    },
    async runJob(context, {processId, versionId = null, extraVariables = {}}) {
        if (!processId) {
            return false;
        }

        const response = await authedAxios.post(`/jobs/${versionId ? `?version_id=${versionId}` : ''}`,
            utils.dataFormatter.serialize({
                stuff: {
                    type: 'Job',
                    variables: extraVariables,
                    processId,
                },
            }));

        await context.dispatch('fetchJobs', processId);

        return utils.dataFormatter.deserialize(response.data);
    },
    async saveVersion(context) {
        context.commit('SET_DATA', {field: 'saving', data: true});
        let version = {
            ...context.state.version,
            type: 'Version',
            id: `${context.state.version.id}`,
        };

        delete version.tasks;
        await context.dispatch('reorderTasks');
        const response = await authedAxios.patch(
                `/versions/${context.state.version.id}/?include=tasks,process`,
                utils.dataFormatter.serialize({stuff: version}),
            ),
            updatedVersion = utils.dataFormatter.deserialize(response.data);

        context.commit('SET_DATA', {field: 'version', data: updatedVersion});
        context.commit('SET_DATA', {field: 'saving', data: false});

        return updatedVersion;
    },
};

export const mutations = {
    SET_ACTIONS(state, availableActions) {
        availableActions.forEach(action => {
            action.variables = {};
        });

        state.availableTasks = availableActions;
    },
    SET_DATA(state, {field, data}) {
        state[field] = data;
    },
};

export const state = () => {
    return {
        filter: '',
        saving: false,
        availableTasks: [],
        deletedTasks: [],
        jobs: [],
        version: {
            tasks: [],
            process: {},
        },
    };
};

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state: state(),
};
