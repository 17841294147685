import * as Sentry from '@sentry/vue';
import Vue from 'vue';

const ENVIRONMENT = process.env.VUE_APP_SENTRY_ENV || process.env.NODE_ENV;

if (!['development', 'test'].includes(ENVIRONMENT) && !window.Cypress) {
    // istanbul ignore next
    Sentry.init({
        Vue,
        dsn: 'https://2c5c9bf11cca400e83522c657340771d@o527262.ingest.sentry.io/5658871',
        environment: ENVIRONMENT || 'production',
        logErrors: ENVIRONMENT === 'staging',
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracingOptions: {
            trackComponents: true,
        },
    });
}
