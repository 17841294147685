import alerts from '@imt/vue-admin-menus/src/store/alerts';
import notifications from '@imt/vue-admin-menus/src/store/notifications';
import toolbox from '@imt/vue-toolbox/src/store';
import utils, {axiosWithAuth} from '@imt/vue-toolbox/src/utils';
import {vueVersioning} from '@imt/vue-versioning/src/store';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Vue from 'vue';
import Vuex from 'vuex';

import builder from '@/store/builder';

Vue.use(Vuex);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('UTC');

export const authedAxios = axiosWithAuth();

export const actions = {
    async fetchProcess(context, processId) {
        const response = await authedAxios.get(
            `/processes/${processId}/?include=versions.tasks,versions.updated_by,versions.created_by`
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchProcesses(context, queryString = '') {
        const response = await authedAxios.get(
            `/processes/?include=versions.tasks,versions.updated_by,versions.created_by${queryString}`
        );

        context.commit('SET_DATA', {field: 'processes', data: utils.dataFormatter.deserialize(response.data)});

        return response;
    },
    async fetchProcessTemplates() {
        const response = await authedAxios.get('/processes/templates/');

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchJob(context, jobId) {
        const response = await authedAxios.get(`/jobs/${jobId}/`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async fetchJobs(context, queryString = '') {
        const response = await authedAxios.get(`/jobs/?${queryString}`);

        context.commit('SET_DATA', {field: 'jobs', data: utils.dataFormatter.deserialize(response.data)});

        return response;
    },
    async createProcess(context, data) {
        const response = await authedAxios.post(
            '/processes/',
            utils.dataFormatter.serialize({stuff: {type: 'Process', tasks: [], ...data}})
        );

        return utils.dataFormatter.deserialize(response.data);
    },
    async createDraft(context, {versionId}) {
        const response = await authedAxios.post(`/versions/${versionId}/draft/?include=tasks,created_by,updated_by`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async publishVersion(context, {versionId, date = null}) {
        const formattedDate = (date !== null) ? dayjs(date).tz().toISOString() : null,
            data = {
                id: versionId,
                publishedAt: formattedDate,
                type: 'Version'
            },
            response = await authedAxios.patch(
                `/versions/${versionId}/?include=tasks,created_by,updated_by`,
                utils.dataFormatter.serialize({stuff: data})
            );

        return utils.dataFormatter.deserialize(response.data);
    },
    async unschedulePublish(context, {versionId}) {
        const response = await authedAxios.patch(`/versions/${versionId}/unschedule/?include=tasks,created_by,updated_by`);

        return utils.dataFormatter.deserialize(response.data);
    },
    async revertVersion(context, {versionId, date = null}) {
        const formattedDate = (date !== null) ? dayjs(date).tz().toISOString() : null,
            data = {
                id: versionId,
                revertedAt: formattedDate,
                type: 'Version',
            },
            response = await authedAxios.patch(
                `/versions/${versionId}/?include=tasks,created_by,updated_by`,
                utils.dataFormatter.serialize({stuff: data})
            );

        return utils.dataFormatter.deserialize(response.data);
    },
    async obsoleteProcess(context, {processId, date = null}) {
        const formattedDate = (date !== null) ? dayjs(date).tz().toISOString() : null,
            data = {
                id: processId,
                obsoleteAt: formattedDate,
                type: 'Process'
            },
            response = await authedAxios.patch(
                `/processes/${processId}/?include=versions.tasks,versions.created_by,versions.updated_by`,
                utils.dataFormatter.serialize({stuff: data})
            );

        return utils.dataFormatter.deserialize(response.data);
    },
    async deleteVersion(context, {versionId}) {
        await authedAxios.delete(`/versions/${versionId}/`);
    },
    async deleteProcess(context, {processId}) {
        await authedAxios.delete(`/processes/${processId}/`);
    },
};

export const mutations = {
    SET_DATA(state, {field, data}) {
        state[field] = data;
    },
};

export const state = () => {
    return {
        processes: [],
        jobs: [],
    };
};

export default new Vuex.Store({
    modules: {
        alerts,
        builder,
        notifications,
        toolbox,
        vueVersioning,
    },
    state: state(),
    mutations,
    actions
});
