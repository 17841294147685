import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faAngleDoubleDown,
    faAngleRight,
    faBracketsCurly,
    faCaretUp,
    faCheckCircle,
    faCog,
    faEmptySet,
    faEye,
    faFilter,
    faFlower,
    faHistory,
    faInfoCircle,
    faPencil,
    faPencilAlt,
    faPlay,
    faPlus,
    faPlusCircle,
    faRedoAlt,
    faRepeat,
    faSave,
    faSpinner,
    faSquare,
    faStream,
    faTasksAlt,
    faTimes,
    faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import Vue from 'vue';

library.add(
    faAngleDoubleDown,
    faAngleRight,
    faBracketsCurly,
    faCaretUp,
    faCheckCircle,
    faCog,
    faEmptySet,
    faEye,
    faFilter,
    faFlower,
    faHistory,
    faInfoCircle,
    faPencil,
    faPencilAlt,
    faPlay,
    faPlus,
    faPlusCircle,
    faRedoAlt,
    faRepeat,
    faSave,
    faSpinner,
    faSquare,
    faStream,
    faTasksAlt,
    faTimes,
    faTrashAlt,
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
