import Vue from 'vue';
import Router from 'vue-router';

import routes from '@/routes';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | Behandle | IMT Platform`;
    next();
});

export default router;
