import {UUID_REGEX} from '@imt/vue-toolbox/src/utils/index';


export default [
    {
        path: '/',
        redirect: {
            name: 'admin',
        },
    },
    {
        path: '/admin',
        children: [
            {
                path: '',
                name: 'admin',
                redirect: {
                    name: 'admin.processes.list',
                },
            },
            {
                path: 'processes',
                name: 'admin.processes.list',
                meta: {
                    title: 'Processes',
                },
                component: () => import(/* webpackChunkName: "ProcessList" */ '@/views/BHProcesses.vue'),
                children: [
                    {
                        path: 'new',
                        name: 'admin.processes.add',
                        meta: {
                            title: 'Processes',
                        },
                        component: () => import(/* webpackChunkName: "ProcessAddModal" */ '@/components/processes/BHAddModal.vue'),
                    },
                    {
                        path: `:id(${UUID_REGEX})`,
                        name: 'admin.processes.edit',
                        meta: {
                            title: 'Processes',
                        },
                        component: () => import(/* webpackChunkName: "ProcessOptionsModal" */ '@/components/processes/BHOptionsModal.vue'),
                    },
                ],
            },
            {
                path: `processes/:id(${UUID_REGEX})/edit`,
                name: 'admin.process-builder',
                meta: {
                    title: 'Process Builder',
                },
                component: () => import(/* webpackChunkName: "ProcessBuilder" */ '@/views/BHProcessBuilder.vue'),
            },
            {
                path: 'jobs',
                name: 'admin.jobs.list',
                meta: {
                    title: 'Jobs',
                },
                component: () => import(/* webpackChunkName: "JobList" */ '@/views/BHJobs.vue'),
                children: [
                    {
                        path: `:id(${UUID_REGEX})`,
                        name: 'admin.job.detail',
                        meta: {
                            title: 'Jobs',
                        },
                        component: () => import(/* webpackChunkName: "JobDetailsModal" */ '@/components/BHJobDetailsModal.vue'),
                    },
                ],
            },
        ],
        component: () => import(/* webpackChunkName: "Base" */ '@/views/BHBase.vue'),
    },
    // Fallback
    {
        path: '*',
        name: 'not-found',
        meta: {
            title: 'Not Found',
        },
        component: () => import(/* webpackChunkName: "NotFound" */ '@imt/vue-kit-car/src/components/errors/NotFound.vue'),
    },
];
